<template>
  <div>
    <button-top v-if="permissions.c" tooltip="Crear empaque" name="Empaque"
      @action="$router.push('/gestion-empaques/create')"></button-top>
    <div class="row justify-content-start mt-1">
      <div class="col-4">
        <b-form-group>
          <label for="search" class="text-success">Buscar (Id. empaque, Nombre)</label>
          <b-input-group class="input-group-merge">
            <b-form-input id="search" v-model="search" class="form-control-merge" name="search" placeholder="" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <b-table :items="search_text" :fields="fields" :busy="load" :current-page="current_page" :per-page="per_page">
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Cargando...</strong>
        </div>
      </template>
      <template #cell(actions)="row">
        <span v-if="permissions.u" class="ml-1 cursor-pointer" v-b-tooltip.hover.top="'Ver empaque'"
          @click="$router.push(`/gestion-empaques/show/${row.item.id}`)">
          <feather-icon icon="MoreVerticalIcon" size="18" />
        </span>
      </template>
    </b-table>
    <b-row class="mt-1">
      <b-col>
        <b-pagination v-model="current_page" :total-rows="row_count" :per-page="per_page"
          aria-controls="my-table"></b-pagination>
      </b-col>
      <b-col>
        <b-form-select class="fl" v-model="per_page" :options="options_per_page"
          title="Registros por página"></b-form-select>
      </b-col>
      <b-col cols="6"></b-col>
    </b-row>
  </div>
</template>
  
<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      load: true,
      search: '',
      fields: [
        {
          key: 'created_at',
          label: 'Creado',
          thStyle: { width: "19%" },
        },
        {
          key: 'id',
          label: 'Id. empaque',
          thStyle: { width: "19%" },
        },
        {
          key: 'name',
          label: 'Nombre',
          thStyle: { width: "19%" },
        },

        {
          key: 'dimensions',
          label: 'Dimensiones',
          thStyle: { width: "19%" },
        },

        {
          key: 'weight',
          label: 'Peso(LBS)',
          thStyle: { width: "19%" },
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: "5%" },
        },
      ],
      items: [],
      current_page: 1,
      per_page: 5,
      options_per_page: [5, 10, 15, 25, 50, 100]
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    row_count() {
      return this.search_text.length;
    },

    search_text() {
      if (!this.items || !this.items.length) {
        return []
      }

      return this.items.filter(item => {
        let props = Object.values(item)
        return props.some(prop => !this.search || ((typeof prop === 'string') ? prop.toLowerCase().includes(this.search.toLowerCase()) : (prop === null ? '' : prop.toString()).toLowerCase().includes(this.search.toLowerCase())))
      })
    },
  },

  methods: {
    async init() {
      await this.getPermission();
      await this.get();
    },

    async get() {
      this.$http
        .get('production/packaging/index')
        .then(response => {
          this.items = response.data.data.map((value) => {
            value.created_at = value.created_at ? this.$moment(value.created_at).format('DD/MM/YYYY') : 'n/a';
            value.weight = value.weight > 0 ? this.formatNumber(value.weight) : 'n/a';
            value.dimensions = value.longitude + 'X' + value.broad + 'X' + value.depth
            return value;
          });
        })
        .catch(error => {
          this.notify("Error", error.response.data.message, 'danger')
        }).finally(
          () => {
            this.load = false;
          }
        )
    }
  }
}
</script>
 
  